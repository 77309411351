//Copyright by Chaafo pvt ltd
//

import React, { Component, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Icofont from 'react-icofont';
import { GlobalContext } from '../../components/GlobalState';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MERCHANTKEY } from '../../App';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function CheckoutItem(props) {

  const {qty, size} = props;
  const { MK, zeroItems, } = useContext(GlobalContext_);
  var T = localStorage.getItem("tokenn");
  const [quantity, setQuantity] = useState(qty);
  const [max] = useState(props.maxValue);
  const [min] = useState(props.minValue || 0);

  const { cart, count, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

  const sortCart = (data) => {
    data.sort((a, b) => {
      if (a.sort_id > b.sort_id) {
        return 1;
      }
      else if (b.sort_id > a.sort_id) {
        return -1;
      }
      else {
        return 0;
      }

    })
    return data;

  }
  useEffect(() => {
    setQuantity(qty || 0);
  }, [qty])
  toast.configure();

  // funtion to call webloadcart api and renders the cart on time.
  //if user is logged in, that means token is there, therefore if case will be executed. Otherwise, else case will be executed.
  async function Loadcart() {

    if (T) {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "_lang=en");
      var formdata = new FormData();
      formdata.append("device_id", T);
      formdata.append("token", T)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      }
      fetch(urls.LoadCart + "?merchant_keys=" + MK, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            setCart(result.details.data.item);
            setTotal(result.details.data.total);
            setCount(result.details.cart_details);
            setEmpty('');
            setPay(result.details);
          }
        })
    }

    else {
      const raw = localStorage.getItem('cart');
      const cartFetchedFromLocalStorage = JSON.parse(raw);
      setCart(sortCart(cartFetchedFromLocalStorage));

      const tRaw = localStorage.getItem('total');
      const totalFetchedFromLocalStorage = JSON.parse(tRaw);
      setTotal(totalFetchedFromLocalStorage);

      const cRaw = localStorage.getItem('Count');
      const countFetchedFromLocalStorage = JSON.parse(cRaw);
      setCount(countFetchedFromLocalStorage);

      const eRaw = localStorage.getItem('empty');
      const EmptyFetchedFromLocalStorage = JSON.parse(eRaw);
      setEmpty(EmptyFetchedFromLocalStorage);

      const pRaw = localStorage.getItem('pay');
      const payFetchedFromLocalStorage = JSON.parse(pRaw);
      setPay(payFetchedFromLocalStorage);

    }
  }

  //Function to add or decrease item
  const addtocart = () => {
    if (quantity >= max) { }
    else {
      setQuantity(quantity + 1);
      props.getValue({ id: props.id, quantity: (quantity + 1) });
    }

    if (T) {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "_lang=en");
      var formdata = new FormData();
      formdata.append("item_id", props.id);
      formdata.append("price", props.discounted_price);
      formdata.append("qty", "1");
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(urls.Add_Delete_Item + "?merchant_keys=" + MK + "&device_id=" + T + "&device_platform=web&transaction_type=pickup&lang=en&_=1644817151280&token=" + T, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            toast.success(result.msg, {
              position: "top-center",
              autoClose: 400
            });

            Loadcart();
          }
        });
    }

    else {
      const currentItem = cart.filter((item) => {
        return item.item_id === props.id && item.size_id === props.size_id;
      });
      if (currentItem === 'undefined' || currentItem.length === 0) {
        // item is not present in cart yet
        const newItemToAdd = { item_id: props.id, size_id: props.size_id, photo: props.image, item_name: props.title, price: props.price, qty: 1, cat_id: props.category_id, sort_id: cart.length + 1 } //

        cart.push(newItemToAdd);
        localStorage.setItem('cart', JSON.parse(cart));
        toast.success('item added', {
          position: "top-center",
          autoClose: 400
        });
        Loadcart();

      }
      else {
        // item is present
        const newCart = cart.filter((item) => {
          return item.item_id !== props.id ? true : item.size_id !== props.size_id;
        });
        newCart.push({ ...currentItem[0], qty: currentItem[0].qty + 1 });
        localStorage.setItem('cart', JSON.stringify(newCart));
        toast.success('item added', {
          position: "top-center",
          autoClose: 400
        });

        Loadcart();

      }
    }
  }


  //Function to remove whole row of the item from cart
  const removecartitem = () => {
   
    if (T) {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "_lang=en");
      var formdata = new FormData();
      formdata.append("row", props.index)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(urls.RemoveItem + "?merchant_keys=" + MK + "&device_id=" + T + "&token=" + T, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            Loadcart();
            toast.success('Item removed', {
              position: "top-center",
              autoClose: 400
            });
            if (cart.length === 1 && Math.abs(qty - 1) === 0) {
              setCart(null);
              setCount(null);
              // Loadcart();
            }
          }
        })
    }
    else {
      // item is present
      const newCart = cart.filter((item) => {
        return item.item_id !== props.id ? true : item.size_id !== props.size_id;
      });
      localStorage.setItem('cart', JSON.stringify(newCart));
      toast.success('cart updated', {
        position: "top-center",
        autoClose: 400
      });
      Loadcart();

    }
  }


  // funtion to decrease item quantity
  const DecreasecartItem = () => {

    if (quantity <= (min)) {

    } else {
      setQuantity(quantity - 1);
      props.getValue({ id: props.id, size_id: props.size_id, quantity: (quantity - 1) });

    }
    if (T) {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "_lang=en");
      var formdata = new FormData();
      formdata.append("item_id", props.id);
      formdata.append("price", props.discounted_price);
      formdata.append("row", props.index);
      formdata.append("qty", -1);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(urls.Add_Delete_Item + "?merchant_keys=" + MK + "&device_id=" + T + "&device_platform=web&transaction_type=pickup&lang=en&_=1644817151280&token=" + T, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            Loadcart();
            toast.success('Cart updated', {
              position: "top-center",
              autoClose: 400
            });
            if (cart.length === 1 && Math.abs(qty - 1) === 0) {
              setCount(null);
              setCart(null);

            }
          }
        })
    }

    else {
      const currentItem = cart.find(
        (item) => item.item_id === props.id && item.size_id === props.size_id
      );
      if (!currentItem) {
        console.error('Current item not found');
        return;
      }
      // item is present
      const newCart = cart.filter(
        (item) => !(item.item_id === props.id && item.size_id === props.size_id)
      );
  
      if (currentItem.qty > 1) {
        const itemToAdd = { ...currentItem, qty: currentItem.qty - 1 };
        newCart.push(itemToAdd);
      }
      localStorage.setItem('cart', JSON.stringify(newCart));
      toast.success('cart updated', {
        position: "top-center",
        autoClose: 400
      });
      Loadcart();

    }
  }
  return (
    <div className="gold-members border-bottom p-2 justify-content-between d-flex flex-wrap align-items-center" style={{}}>
      <div className='d-flex align-items-start gap-1'>
        <div className='d-flex align-items-center gap-1'>
        <Icofont icon="ui-press" className="text-danger1 food-item" />
      
        </div>
       <div className='d-flex text-start flex-column align-items-start' style={{width: '100%'}}>
       <p className=" m-0 p-0 text-black align-items-start">{props.itemName}</p>
       <span className='m-0 p-0' style={{color: 'grey', fontSize: '0.8rem', width: '100%'}}>{props.servingSize}</span>
        </div> 
      </div>
     
      <div className='d-flex align-items-center gap-1' style={{ marginLeft: 'auto' }}>

        <p className="text-gray mb-0">{props.priceUnit}{Math.abs((props.price) * (qty))}</p>
        <span className="count-number float-right">
          <Button variant="outline-secondary " onClick={DecreasecartItem} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
          <input className="count-number-input p-0" type="text" value={Math.abs(qty)} readOnly />
          <Button variant="outline-secondary " onClick={addtocart} className="btn-sm right inc" > <Icofont icon="icofont-plus" /> </Button>
        </span>
        <Button variant="" className="btn-sm float-right" style={{}} onClick={removecartitem}> <Icofont icon="bin" /> </Button>
      </div>
    </div>
  );
}

CheckoutItem.propTypes = {
  size_id: PropTypes.number.isRequired,
  itemName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  discounted_price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  qty: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  getValue: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  category_id: PropTypes.number.isRequired
};
CheckoutItem.defaultProps = {
  show: true,
  priceUnit: '$'
}



export default CheckoutItem;

